import * as React from "react"
import { Hero } from "../components/Hero/Hero"
import SEO from "../components/SEO"
import heroImage from '../images/hero/home.jpg'
import '../styles.scss'

const HomePage = () => (
  <>
    <SEO title="Home" />
    <Hero backgroundImage={heroImage}>
      <div className='container-blue-90 home'>
        <h1 className='hero-caption'>
          <span>'The Best Accountable</span>
          Creative Influencers
          <span>I have ever worked with'</span>
        </h1>
      </div>
    </Hero>
  </>
)

export default HomePage